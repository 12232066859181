/*-------------------------------------------------------------------------------------------*/
/* HEADER */
/*-------------------------------------------------------------------------------------------*/

$header-height: 80px;

#header {
    border-bottom: 1px solid rgba(248, 249, 249, .16);
    padding: 8px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z(5);
    background-color: rgba(palette(black),0.25);
    transition: top $duration ease-in-out, background-color $duration ease-in-out;
    &.nav-fixed,
    &.nav-color {
        background-color: palette(white, pure);
        border-color: palette(white, light);
        //top: (0 - $header-height);

        .logo-color {
            opacity: 1;
        }

        .logo-white {
            opacity: 0;
        }

        #primary-nav > ul > li {
            &.current-menu-item {
                > a {
                    @include breakpoint($tablet) {
                        color: palette(yellow);
                    }
                }
            }
            > a {
                @include breakpoint($tablet) {
                    color: palette(grey, light);
                }
            }
        }
    }
}

.admin-bar #header {
    top: 46px;
    &.nav-fixed,
    &.nav-color {
        top: 0;
    }

    .header-nav {
        top: 0;
    }
    @include breakpoint($tablet) {
        top: 32px;
    }
}

.header-logo {
    height: 44px;
    width: 170px;
    display: inline-block;
    position: relative;
    vertical-align: top;
	margin-top: 0;
    @include breakpoint($tablet) {
        float: left;
        margin-top: 3px;
        width: 185px;
        z-index: 10;
    }

    .logo-color {
        opacity: 0;
    }
}

.logo-color,
.logo-white {
    width: 170px;
    position: absolute;
    transition: opacity $duration ease-in-out;
    @include breakpoint($tablet) {
        width: 209px;
    }
}

main {

}

// Let's Talk
.quicklink-top {
    @include breakpoint($tablet) {
        float: right;
        font-size: 12px;
        margin: 5px 0 9px 0;
        position: relative;
        z-index: 10;
    }
    a {
        display: block;
        font-family: $josefin;
        font-weight: $font-weight-denim;
        min-width: 85px;
        text-align: center;
        text-transform: uppercase;
        &:after {
            background-color: palette(yellow);
            content: '';
            display: block;
            height: 2px;
            width: 100%;
        }
    }
    &.quicklink-top-first {
        margin-right: 15px;
    }
}
