// NEW: Sass color map
// When cloning to new site, update color map to most closely reflect primary colors and lighter/darker shades of said colors
// This will be especially easy when we begin receiving style guides
// Naming conventions within single color, in order:
// lightest, lighter, light, base, dark, darker, darkest
$COLORS: (
    blue: (
        base: #2493F2,
        dark: #46709D,
    ),
    yellow: (
        base: #F0C159,
        dark: #E3AB29,
    ),
    red: (
        base: #ff0000,
    ),
    purple: (
        base: #800080,
    ),
    grey: (
        lightest: #F8F9F9,
        lighter: #dadada,
        light: #909092,
        base: #464647,
        dark: #353537,
    ),
    white: (
        light: #eceeee,
        base: #f8f9f9, // Use slightly off-white/off-black, more natural and easier on the eyes
        pure: #fff,
    ),
    black: (
        light: #1c1c1c,
        base: #101010,
        pure: #000,
    ),
);

// Palette color map retrieval function
@function palette($color, $shade: base) { // Default shade: base
    // Return warning if color is invalid
    @if not map-has-key($COLORS, $color) {
        @warn "There is no color mapped to '#{$color}'";
        @return null;
    }

    // Grab shade map for given color
    $color-map: map-get($COLORS, $color);
    // Return warning if shade is invalid
    @if not map-has-key($color-map, $shade) {
        @warn "There is no '#{$shade}' for the color '#{$color}'";
        @return null;
    }

    // Return the proper shade
    @return map-get($color-map, $shade);
}

// Functional color variables
$color-text: palette(black, light);
$color-accent: palette(red);

$color-highlight: palette(yellow);

$color-link: palette(yellow);
$color-link-hover: palette(yellow, dark);
$color-link-visited: palette(yellow, dark);

$color-form-text: palette(black);
$color-form-placeholder: palette(grey);
$color-form-required: palette(black);
$color-form-border: palette(black);
$color-form-error: palette(red);

$color-shadow: rgba(palette(black), .8);
$color-body: palette(white);

// Social Media
$color-facebook: #3b5998;
$color-twitter: #00aced;
$color-linkedin: #007bb6;
