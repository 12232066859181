/*-------------------------------------------------------------------------------------------*/
/* BOOTSTRAP OVERRIDES */
/*-------------------------------------------------------------------------------------------*/

.container {
    @include breakpoint($phablet){
        padding-left: 30px;
        padding-right: 30px;
    }
}

/**
  * Custom Bootstrap 3 grid for 480-767px layout
  *
  * A new set of column classes (with class prefix .col-ms- ) for Bootstrap 3
  * to make Medium Small grids for supporting 480-767px
  *
  * This will push the default Bootstrap 3 Extra Small column (class prefix .col-xs- )
  * to support 1-479px instead of 1-767px
  */

.col-ms-1,
.col-ms-2,
.col-ms-3,
.col-ms-4,
.col-ms-5,
.col-ms-6,
.col-ms-7,
.col-ms-8,
.col-ms-9,
.col-ms-10,
.col-ms-11,
.col-ms-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 480px) and (max-width: 767px) {
    .container {
        max-width: 748px;
    }
    .hidden-xs {
        display: block !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .col-ms-1,
    .col-ms-2,
    .col-ms-3,
    .col-ms-4,
    .col-ms-5,
    .col-ms-6,
    .col-ms-7,
    .col-ms-8,
    .col-ms-9,
    .col-ms-10,
    .col-ms-11,
    .col-ms-12 {
        float: left;
    }
    .col-ms-12 {
        width: 100%;
    }
    .col-ms-11 {
        width: 91.66666667%;
    }
    .col-ms-10 {
        width: 83.33333333%;
    }
    .col-ms-9 {
        width: 75%;
    }
    .col-ms-8 {
        width: 66.66666667%;
    }
    .col-ms-7 {
        width: 58.33333333%;
    }
    .col-ms-6 {
        width: 50%;
    }
    .col-ms-5 {
        width: 41.66666667%;
    }
    .col-ms-4 {
        width: 33.33333333%;
    }
    .col-ms-3 {
        width: 25%;
    }
    .col-ms-2 {
        width: 16.66666667%;
    }
    .col-ms-1 {
        width: 8.33333333%;
    }
    .col-ms-pull-12 {
        right: 100%;
    }
    .col-ms-pull-11 {
        right: 91.66666667%;
    }
    .col-ms-pull-10 {
        right: 83.33333333%;
    }
    .col-ms-pull-9 {
        right: 75%;
    }
    .col-ms-pull-8 {
        right: 66.66666667%;
    }
    .col-ms-pull-7 {
        right: 58.33333333%;
    }
    .col-ms-pull-6 {
        right: 50%;
    }
    .col-ms-pull-5 {
        right: 41.66666667%;
    }
    .col-ms-pull-4 {
        right: 33.33333333%;
    }
    .col-ms-pull-3 {
        right: 25%;
    }
    .col-ms-pull-2 {
        right: 16.66666667%;
    }
    .col-ms-pull-1 {
        right: 8.33333333%;
    }
    .col-ms-pull-0 {
        right: auto;
    }
    .col-ms-push-12 {
        left: 100%;
    }
    .col-ms-push-11 {
        left: 91.66666667%;
    }
    .col-ms-push-10 {
        left: 83.33333333%;
    }
    .col-ms-push-9 {
        left: 75%;
    }
    .col-ms-push-8 {
        left: 66.66666667%;
    }
    .col-ms-push-7 {
        left: 58.33333333%;
    }
    .col-ms-push-6 {
        left: 50%;
    }
    .col-ms-push-5 {
        left: 41.66666667%;
    }
    .col-ms-push-4 {
        left: 33.33333333%;
    }
    .col-ms-push-3 {
        left: 25%;
    }
    .col-ms-push-2 {
        left: 16.66666667%;
    }
    .col-ms-push-1 {
        left: 8.33333333%;
    }
    .col-ms-push-0 {
        left: auto;
    }
    .col-ms-offset-12 {
        margin-left: 100%;
    }
    .col-ms-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-ms-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-ms-offset-9 {
        margin-left: 75%;
    }
    .col-ms-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-ms-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-ms-offset-6 {
        margin-left: 50%;
    }
    .col-ms-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-ms-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-ms-offset-3 {
        margin-left: 25%;
    }
    .col-ms-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-ms-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-ms-offset-0 {
        margin-left: 0%;
    }
}

.visible-xs,
.visible-ms {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-ms-block,
.visible-ms-inline,
.visible-ms-inline-block {
    display: none !important;
}

@media (max-width: 479px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 479px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 479px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 479px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .visible-ms {
        display: block !important;
    }
    table.visible-ms {
        display: table;
    }
    tr.visible-ms {
        display: table-row !important;
    }
    th.visible-ms,
    td.visible-ms {
        display: table-cell !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .visible-ms-block {
        display: block !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .visible-ms-inline {
        display: inline !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .visible-ms-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 479px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .hidden-ms {
        display: none !important;
    }
}
