/*-------------------------------------------------------------------------------------------*/
/* FOOTER */
/*-------------------------------------------------------------------------------------------*/

#footer {
    background-color: palette(black);
    color: palette(grey, light);
    font-size: 12px;
    padding: 40px 0;
    position: relative;
    z-index: z(2);

    h3 {
        font-size: 14px;
        font-weight: $font-weight-heavy;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 15px;
        text-transform: uppercase;
    }

    ul.social-media,
    ul.contact-info {
        margin: 0 0 25px;
    }
}

#menu-footer-menu {
    border-top: 1px solid #1C1C1C;
    margin: 0;
    padding-top: 25px;
    @include breakpoint($phablet) {
        display: flex;
    }
    > li {
        &:first-of-type {
            > a {
                @include breakpoint($phablet) {
                    padding-left: 0;
                }
            }
        }
        > a {
            color: palette(grey, light);
            display: block;
            font-weight: $font-weight-denim;
            line-height: 1;
            padding: 10px 0;
            text-transform: uppercase;
            transition: color $duration ease-in-out;
            @include breakpoint($phablet) {
                padding: 10px 15px;
            }
            &:hover {
                color: palette(yellow);
            }
        }
    }
}
