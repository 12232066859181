/*-------------------------------------------------------------------------------------------*/
/* PANELS: VALUE BLOCKS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-value-blocks {
  margin: 64px 0;
  @include breakpoint($tablet) {
    margin: 120px 0;
  }

  .value-block {
    background-color: palette(white);
    border-bottom: 4px solid palette(white, light);
    color: palette(grey);
    display: block;
    margin: 0 auto 24px;
    max-width: 325px;
    overflow: hidden;
    padding: 22px;
    position: relative;
    text-align: center;
    @include breakpoint($tablet) {
      max-width: none;
      padding: 28px;
      text-align: left;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -200%;
      background-color: palette(yellow);
      height: 100%;
      width: 140%;
      transform: skew(-15deg);
      z-index: 1;
      transition: all .25s linear;
    }

    &:hover {
      &:before {
        left: -40px;
      }

      i,
      h4 {
        color: palette(white, pure);
      }
    }
  }

  i {
    color: palette(yellow);
    font-size: 24px;
    position: relative;
    transition: all $duration ease-in-out;
    z-index: 2;
  }

  h4 {
    font-family: $futura;
    font-size: 24px;
    font-weight: $font-weight-heavy;
    line-height: 30px;
    margin: 0;
    position: relative;
    transition: all $duration ease-in-out;
    z-index: 2;
    @include breakpoint($tablet) {
      width: 75%;
    }
  }
}