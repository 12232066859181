/*-------------------------------------------------------------------------------------------*/
/* BG-OVERLAY */
/*-------------------------------------------------------------------------------------------*/
@mixin bg-overlay($background: rgba(palette(grey), .5), $pseudo: 'before') {
    position: relative;
    &:#{$pseudo} {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $background;
    }
}

/*-------------------------------------------------------------------------------------------*/
/* CLEARFIX */
/*-------------------------------------------------------------------------------------------*/
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/*-------------------------------------------------------------------------------------------*/
/* WEBFONTS */
/*-------------------------------------------------------------------------------------------*/
@mixin font-face($family, $file, $weight, $style) {
    @font-face {
        font-family: $family;
        src: url('#{$path-font}/#{$file}-webfont.eot');
        src: url('#{$path-font}/#{$file}-webfont.eot?#iefix') format('embedded-opentype'),
        url('#{$path-font}/#{$file}-webfont.woff2') format('woff2'),
        url('#{$path-font}/#{$file}-webfont.woff') format('woff'),
        url('#{$path-font}/#{$file}-webfont.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
    }
}

/*-------------------------------------------------------------------------------------------*/
/* TEXT */
/*-------------------------------------------------------------------------------------------*/
@mixin text($size, $l-height: null, $weight: null, $color: null) {
    font-size: $size;
    line-height: $l-height;
    font-weight: $weight;
    color: $color;
}
