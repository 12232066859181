/*-------------------------------------------------------------------------------------------*/
/* PAGINATION */
/*-------------------------------------------------------------------------------------------*/

.panel-pagination,
.ww-pagination {
    margin: 30px 0 60px 0;
	padding: 0 !important;
	text-align: center;
	
	
	.facetwp-pager-label {
		display: none;
	}
	
	.facetwp-page,
	.page-numbers {
		padding: 0 10px;
		color: palette(grey);
		font-family: $josefin;
		font-size: calcRem(24);
		font-weight: 800;
		
		&:hover {
			color: palette(black);
		}
		
		&.active,
		&.current {
			color: palette(white);
            background-color: palette(yellow);
		}
		
		.next-page,
		.prev-page {
			font-size: calcRem(13);
		}
		
//			&.last-page {
//				font-size: 0px;
//				padding-left: 0;
//
//				&::after {
//					font-family: 'icomoon';
//					font-size: calcRem(18);
//					content: '\e911';
//				}
//			}
//
//			&.first-page {
//				font-size: 0px;
//				padding-right: 0;
//
//				&::before {
//					font-family: 'icomoon';
//					font-size: calcRem(18);
//					content: '\e913';
//				}
//			}
	}
}