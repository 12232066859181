/*-------------------------------------------------------------------------------------------*/
/* PANELS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-numbers + .panel.panel-timeline,
.panel.panel-numbers + .panel.panel-gallery-carousel,
.panel.panel-numbers + .panel.panel-callouts,
.panel.panel-timeline + .panel.panel-numbers,
.panel.panel-timeline + .panel.panel-gallery-carousel,
.panel.panel-timeline + .panel.panel-callouts,
.panel.panel-callouts + .panel.panel-timeline,
.panel.panel-callouts + .panel.panel-gallery-carousel,
.panel.panel-callouts + .panel.panel-numbers {
  margin-top: $panel-mobile-padding;
  @include breakpoint($tablet) {
    margin-top: $panel-desktop-padding;
  }
}

.panel.panel-ctas + .panel.panel-video,
.panel.panel-ctas + .panel.panel-brands,
.panel.panel-video + .panel.panel-ctas,
.panel.panel-brands + .panel.panel-ctas,
.panel.panel-text + .panel.panel-ctas,
.panel.panel-text-image + .panel.panel-brands,
.panel.panel-value-blocks + .panel.panel-text,
.panel.panel-text + .panel.panel-value-blocks {
  margin-top: 0;
}

