/*-------------------------------------------------------------------------------------------*/
/* PANELS: CALLOUTS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-callouts {
  background-color: palette(black, light);
  color: palette(grey);
  margin-bottom: $panel-mobile-padding;
  margin-top: $panel-mobile-padding;
  padding-bottom: $panel-mobile-padding;
  padding-top: $panel-mobile-padding;
  position: relative;
  @include breakpoint($tablet) {
    margin-bottom: $panel-desktop-padding;
    margin-top: $panel-desktop-padding;
    padding-bottom: $panel-desktop-padding;
    padding-top: $panel-desktop-padding;
  }

  .col-lg {
    &:last-of-type {
      .callout-container {
        margin-bottom: 0;
      }
    }
  }

  h2 {
    color: palette(white, pure);
    font-size: 32px;
    line-height: 44px;
    margin: 0 0 40px;
    text-align: center;
    @include breakpoint($tablet) {
      font-size: 64px;
      line-height: 68px;
      text-align: left;
    }
  }

  .concord-mark {
    left: 0;
    max-width: 115px;
    position: absolute;
    top: 55px;
    @include breakpoint($tablet) {
      left: auto;
      max-width: 205px;
      right: 0;
      top: 50%;
      transform: rotate(180deg) translateY(50%);
    }
  }

  #concord-mark-fill1,
  #concord-mark-fill2 {
    fill: palette(black);
  }



  .callout-container {
    //border: 1px solid #303030;
    margin: 0 auto 40px;
    max-width: 420px;
    background-color: palette(white);
    @include breakpoint($tablet) {
      max-width: none;
    }

      .callout-image {
          display: inline-block;
          margin: 0 auto;
      }
      .callout-content {
          padding: 12px 22px 10px 22px;
      }
  }

  i {
    color: palette(yellow);
    font-size: 46px;
  }

  h3 {
    color: palette(grey, dark);
      font-size: 20px;
    margin: 12px 0;
  }
}