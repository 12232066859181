/*-------------------------------------------------------------------------------------------*/
/* PANELS: VIDEO */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-video {
  margin-bottom: $panel-mobile-padding;
  margin-top: $panel-mobile-padding;
  @include breakpoint($tablet) {
    margin-bottom: $panel-desktop-padding;
    margin-top: $panel-desktop-padding;
  }

  .video-container {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: palette(white, pure);
    position: relative;
    margin: 0 auto;
    max-width: 325px;
    min-height: 325px;
    @include breakpoint($phablet) {
      max-width: 850px;
      min-height: 520px;
    }
    @include breakpoint($tablet) {
      max-width: 1168px;
    }
    &:before {
      background-color: rgba(palette(black), .4);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .video-description-container {
    left: 0;
    margin: 0 auto;
    right: 0;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    margin: 0 0 10px;
    @include breakpoint($tablet) {
      font-size: 64px;
    }
  }

  .video-description {
    font-size: 18px;
    line-height: 32px;
    @include breakpoint($tablet) {
      font-size: 24px;
      line-height: 38px;
    }
  }

  .play-button {
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 80px;
    position: absolute;
    @include breakpoint($tablet) {
      bottom: 80px;
    }

      img {
          border-radius: 50%;
          box-shadow: 2px 4px 15px rgba(palette(black), 0.4);
      }

      &:hover {
          img {
              box-shadow: none;
          }
      }
  }
}
