/*-------------------------------------------------------------------------------------------*/
/* PANELS: TEXT */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-text {
  margin: $panel-mobile-padding 0;
  position: relative;
  overflow: hidden;
  @include breakpoint($tablet) {
    //min-height: 463px;
    margin: $panel-desktop-padding 0;
  }

    .portfolio & {
        margin: 0;
        padding: $header-height 0 120px 0;
        background: linear-gradient(palette(grey) $header-height, palette(white) $header-height);

        .container {
            max-width: 100%;
            padding: 0;
        }
    }

  &.panel-text-type-title {
    // Re-order the Subtitle & Title fields for the 'Title' text panel option
    .col-flex {
      display: flex;
      flex-flow: column;
    }

    .text-subtitle {
      order: 2;
    }

    .text-title {
      order: 1;
    }

    // Different subtitle styling for the 'Title' text panel option
    .text-subtitle {
      font-family: $freight;
      font-size: 18px;
      line-height: 36px;
      @include breakpoint($tablet) {
        font-size: 24px;
        line-height: 40px;
      }
    }
  }

  &.panel-text-type-content {
    .text-subtitle {
      font-size: 12px;
      font-weight: $font-weight-heavy;
      letter-spacing: 1.5px;
      line-height: 16px;
      margin-bottom: 10px;
      text-transform: uppercase;
      @include breakpoint($tablet) {
        font-size: 14px;
        font-weight: $font-weight-denim;
        line-height: 18px;
      }
    }

    .text-title {
      margin: 0 0 15px;
      @include breakpoint($tablet) {
        margin: 0 0 25px;
      }
    }
  }

  &.panel-text-background {
    &:before {
      background: url("../images/concord-mark.png") center right/100% no-repeat;
      bottom: 0;
      content: "";
      display: block;
      height: 232px;
      position: absolute;
      right: 0;
      width: 132px;
      @include breakpoint($tablet) {
          height: 100%;
          max-height: 463px;
        left: 0;
        right: auto;
        transform: rotate(180deg);
        //width: 251px;
      }
    }
  }

  + .panel-text {
    padding-top: 0;
  }

  h2, h3, h4, h5, h6 {
    &:first-of-type {
      margin-top: 0;
    }
  }

  .text-subtitle {
    color: palette(grey, light);
  }

  .text-title {
    margin: 0 0 8px;
    @include breakpoint($tablet) {
      margin: 0 0 12px;
    }
  }
}
