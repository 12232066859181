.panel.panel-gallery-carousel {
  position: relative;
  &:before {
    @include breakpoint($tablet) {
      background-color: palette(blue);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 40%;
    }
  }

  .flickity-slider {
    transform: none !important;
  }

  .carousel-cell {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    z-index: 1;
    @include breakpoint($tablet) {
      display: flex;
      flex-flow: wrap;
    }
    &.is-selected {
      opacity: 1;
      z-index: 2;
    }
  }

  .image-container {
    display: block;
    padding: 0 30px;
    @include breakpoint($tablet) {
      height: auto;
      padding: 70px 0;
      width: 60%;
    }

    img {
      display: block;
      margin: 0 auto;
      @include breakpoint($phablet) {
        margin: 0 auto;
      }
      @include breakpoint($tablet) {
        margin: 0;
      }
    }
  }

  .gallery-information {
    background-color: palette(blue);
    color: palette(white, pure);
    padding: 30px 30px 45px 30px;
    @include breakpoint($tablet) {
      background-color: transparent;
      padding: 0;
      position: relative;
      width: 40%;
    }
  }

  .gallery-information-inner {
    max-width: 500px;
    margin: 0 auto;
    @include breakpoint($tablet) {
      max-width: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
      left: 0;
      right: 0;
    }
    @include breakpoint($desktop) {
      width: 65%;
    }
  }

  h3 {
    font-size: 28px;
      line-height: 32px;
    margin: 15px 0 0 0;
    @include breakpoint($tablet) {
        font-size: 40px;
        line-height: 46px;
        margin: 15px 0 30px;
    }
  }

  .button-line {
    color: palette(white, pure);
    &:after {
      background-color: palette(white, pure);
    }
  }

  .slider-counter {
    color: palette(white, pure);
    @include breakpoint($tablet) {
      bottom: 20px;
      left: 35%;
      right: auto;
    }
    @include breakpoint($desktop) {
      left: 37%;
    }
    span {
      color: palette(blue, dark);
    }
  }
}