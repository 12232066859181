// Add styling variables that pertain to specific sub-sections/components of your site here, with a comment above each independent sub-section
// Example:

// Panel Variables
$panel-margin-mobile: 3rem;
$panel-margin-tablet: 4rem;
$panel-margin-desktop: 5rem;

// Panel Padding
$panel-mobile-padding: 64px;
$panel-desktop-padding: 120px;
