/*-------------------------------------------------------------------------------------------*/
/* SINGLE POST */
/*-------------------------------------------------------------------------------------------*/

body.blog,
body.category {
  article.type-post {
    background-color: palette(grey, lightest);
    padding: 68px 16px;
    min-height: 250px;
    position: relative;
    margin-bottom: 30px;
    overflow: visible;
    @include breakpoint($tablet) {
      display: flex;
      margin-bottom: 100px;
      padding: 68px;
    }

    .entry-content {
      @include breakpoint($tablet) {
        width: 60%;
      }
    }
  }
}

body.single-post {
  .entry-content {
    padding-top:$panel-mobile-padding;
    @include breakpoint($tablet) {
      padding-top: $panel-desktop-padding;
    }
  }

  .entry-footer {
    padding-bottom: $panel-mobile-padding;
    @include breakpoint($tablet) {
      padding-bottom: $panel-desktop-padding;
    }
  }
}

article.type-post {
  .entry-header {
    @include breakpoint($tablet) {
      padding-right: 10%;
      width: 40%;
    }
      .entry-meta {
          font-family: $josefin;
      }
  }

  .entry-content {
    color: palette(grey, light);
    font-size: 16px;
    line-height: 34px;
  }

  .entry-meta {
    a {
      display: block;
      font-size: 12px;
      font-weight: $font-weight-denim;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  h2.entry-title {
    font-family: $freight;
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 32px;
    margin: 5px 0;
    @include breakpoint($phablet) {
      font-size: 24px;
      line-height: 38px;
    }

    a {
      color: palette(grey, light);
    }
  }

  .button {
    position: absolute;
    bottom: -15px;
    right: 0;
    margin: 0;
  }
}
