/*-------------------------------------------------------------------------------------------*/
/* PAGINATION */
/*-------------------------------------------------------------------------------------------*/

.pagination {
  justify-content: center;
  margin-top: 65px;

  a,
  .current {
    display: block;
    font-weight: $font-weight-heavy;
    //line-height: 1;
    padding: 10px;
    min-width: 35px;
    min-height: 35px;
    text-align: center;
  }

  a {
    color: palette(yellow);
  }

  .current,
  .prev,
  .next {
    background-color: palette(yellow);
    color: palette(white);
  }
    .prev, .next {
        font-size: 12px;
    }
}
