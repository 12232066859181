/*-------------------------------------------------------------------------------------------*/
/* FONTS */
/*-------------------------------------------------------------------------------------------*/

//@include font-face('Brandon Grotesque', 'brandon_med', '400', 'normal');

/*-------------------------------------------------------------------------------------------*/
/* DEFAULT TYPOGRAPHY */
/*-------------------------------------------------------------------------------------------*/

html {
    font-family: $freight;
    font-size: 62.5%; // Set rem value as Base 10 for ease of entering pixel values from comp while sticking with relative units (62.5% * 16px = 10px)
    font-weight: $font-weight-normal;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
}

body {
    color: $color-text;
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    background-color: palette(white, pure);
    @include breakpoint($tablet) {
        font-size: 17px;
        line-height: 34px;
    }
}

a {
    &:link {
        color: $color-link;
        text-decoration: none;
    }
    &:visited {
        color: $color-link-visited;
    }
    &:hover {
        color: $color-link-hover;
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
%h1, %h2, %h3, %h4, %h5, %h6 {
    color: inherit;
    font-family: $josefin;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1;
}

h1, h2, h3,
.h1, .h2, .h3,
%h1, %h2, %h3 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}

h4, h5, h6,
.h4, .h5, .h6,
%h4, %h5, %h6 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

h1, .h1, %h1 {
    font-size: 32px;
    font-weight: $font-weight-heavy;
    line-height: 48px;
    @include breakpoint($phablet) {
        font-size: 64px;
        line-height: 72px;
    }
}

h2, .h2, %h2 {
    font-size: 28px;
    font-weight: $font-weight-heavy;
    line-height: 36px;
    @include breakpoint($phablet) {
        font-size: 40px;
        line-height: 48px;
    }
}

h3, .h3, %h3 {
    font-size: 24px;
    font-weight: $font-weight-heavy;
    line-height: 32px;
    @include breakpoint($phablet) {
        font-size: 24px;
        line-height: 32px;
    }
}

h4, .h4, %h4 {
    font-family: $freight;
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 36px;
    @include breakpoint($phablet) {
        font-size: 32px;
        line-height: 40px;
    }
}

h5, .h5, %h5 {
    font-size: 12px;
    font-weight: $font-weight-heavy;
    line-height: 14px;
    @include breakpoint($phablet) {
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: $font-weight-denim;
        line-height: 16px;
    }
}

h6, .h6, %h6 {
    font-size: 10px;
    font-weight: $font-weight-heavy;
    line-heihgt: 12px;
    text-transform: uppercase;
    @include breakpoint($phablet) {
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 14px;
    }
}

p {
    margin-top: 0;
    margin-bottom: 2.8rem;
}

p, ul, ol, dl, pre, table, blockquote {
    margin-top: 0;
    margin-bottom: 2.8rem;
}

ul,
ol {
    &:not('.list-inline'),
    &:not('.list-unstyled') {
        padding-left: 30px;
        > li {
            padding-left: 10px;
        }
    }

}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}

blockquote {
    padding: 10px 20px;
    font-size: 2.8rem;
    border-left: 5px solid $color-accent;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer {
    display: block;
    font-size: 1.6rem;
    line-height: 1.76rem;
}

blockquote footer:before {
    content: '\2014 \00A0';
}

small, .small {
    font-size: 1.36rem;
}

mark, .mark {
    padding: .32rem;
    background-color: $color-highlight;
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted $color-text;
}

.section {
    font-family: $freight;
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 36px;
    @include breakpoint($phablet) {
        font-size: 32px;
        line-height: 40px;
    }
}

.subhead {
    font-size: 12px;
    font-weight: $font-weight-heavy;
    letter-spacing: 1px;
    line-height: 14px;
    @include breakpoint($phablet) {
        font-size: 14px;
        font-weight: $font-weight-denim;
        line-height: 16px;
    }
}

.uppercase {
    text-transform: uppercase;
}
