/*-------------------------------------------------------------------------------------------*/
/* PANELS: NUMBERS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-numbers {
  background-color: palette(black, light);
  color: palette(grey, light);
  padding: $panel-mobile-padding 0;
  position: relative;
  @include breakpoint($phablet) {
    padding: 100px 0;
  }
  @include breakpoint($tablet) {
    padding: 170px 0;
  }

  .col-md:last-of-type {
    .number-container:after {
      content: none;
    }
  }

  .concord-mark {
    display: none;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 150px;
    @include breakpoint($tablet) {
      display: block;
      max-width: 200px;
    }
  }

  #concord-mark-fill1,
  #concord-mark-fill2 {
    fill: palette(black);
  }

  .number-container {
    &:after {
      border-bottom: 1px solid #303030;
      content: "";
      display: block;
      margin: 0 auto 36px;
      padding-bottom: 36px;
      width: 145px;
      @include breakpoint($phablet) {
        border-bottom: none;
        border-right: 1px solid #303030;
        height: 80px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
      }
      @include breakpoint($tablet) {
        height: 145px;
      }
    }
  }

  .number {
    color: palette(grey, lightest);
    font-size: 64px;
    font-weight: $font-weight-denim;
    line-height: 1;
    transition: opacity $duration ease-in-out;
    @include breakpoint($tablet) {
      margin-bottom: 20px;
    }
  }

  .number-description {
    font-weight: $font-weight-denim;
  }
}