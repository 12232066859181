/*-------------------------------------------------------------------------------------------*/
/* NAVIGATION */
/*-------------------------------------------------------------------------------------------*/

#header {
  .navigation {
    //@include breakpoint($tablet) {
    //  align-items: center;
    //  display: flex;
    //  padding: 0;
    //}
  }
}

.header-nav {
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
    font-family: $josefin;
  overflow-y: scroll;
  overflow-x: hidden;
  background: palette(white, pure) url("../images/concord-mark.png") center right/80% no-repeat;
  transition: top $duration, opacity $duration ease-in-out, visibility $duration ease-in-out;
  visibility: hidden;
  opacity: 0;
  @include breakpoint($tablet) {
    background: transparent;
    display: block;
    padding: 0 200px;
    opacity: 1;
    position: absolute;
    overflow: visible;
    visibility: visible;
  }
  @include breakpoint($desktop) {
    padding: 0 250px;
  }
}

.admin-bar .header-nav {
  top: 32px;
  @include breakpoint($tablet) {
    top: auto;
  }
}

.header-nav-visible {
  visibility: visible;
  opacity: 1;
  @include breakpoint($tablet) {
    display: inherit;
  }
}

.header-nav-opaque {
  opacity: 1;
}

.mobile-menu-is-open {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  @include breakpoint($tablet) {
    overflow: visible;
    height: auto;
  }
}

#mobile-menu-header {
  border-bottom: 1px solid palette(white, light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  background-color: palette(white, pure);
  width: 100%;
  #close-mobile-menu {
    color: palette(yellow);
    margin-right: 10px;
    cursor: pointer;
  }
  @include breakpoint($tablet) {
    display: none;
  }

  .logo-color {
    position: relative;
  }
}

#primary-nav {
  display: block;
  //position: absolute;
  //top: 50%;
  //transform: translateY(-50%);
    padding-top: 15px;
  width: 100%;
  @include breakpoint($tablet) {
    position: relative;
      padding-top: 0;
    //top: auto;
    //transform: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  > ul {
    @include breakpoint($tablet) {
      flex-direction: row;
      justify-content: center;
    }
    > li {
      display: block;
      position: relative;
      @include breakpoint($tablet) {
        position: static;
      }
      &.current-menu-item {
        > a {
         color: palette(yellow);
          &:after {
            background-color: palette(yellow);
            content: "";
            display: block;
            height: 2px;
            width: 100%;
          }
        }
      }
      > a {
        color: palette(grey, light);
        display: block;
        font-size: 20px;
        font-weight: $font-weight-heavy;
        padding: 10px 15px;
        text-transform: uppercase;
        @include breakpoint($tablet) {
          color: palette(white, pure);
          font-size: 12px;
          font-weight: $font-weight-denim;
          letter-spacing: 1px;
          padding: 10px 20px;
        }
          @include breakpoint($desktop) {
              padding: 10px 30px;
          }
      }

        .submenu-open {
            cursor: pointer;
            color: palette(grey);
            display: block;
            font-size: 1.8rem;
            padding: 18px 20px;
            position: absolute;
            right: 18px;
            text-align: center;
            //background-color: yellow;
            top: 0;
            z-index: 1;
            @include breakpoint($phablet) {
                right: 10px;
            }
            @include breakpoint($tablet) {
                display: none;
            }
            &:before {
                display: block;
                transform: rotate(0);
                transition: $duration ease-in-out;
            }

            &.js-arrow-active {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }

    }
  }
  > ul ul.sub-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    margin-top: 25px;
    padding: 10px 15px;
    //text-align: center;
    transition: $duration ease-in-out;
    @include breakpoint($tablet) {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      background-color: palette(white, pure);
      box-shadow: 0 8px 8px rgba(0, 0, 0, .15);
      columns: 2;
      column-width: 30%;
      break-inside: avoid;
      left: -200px;
      margin-top: 48px;
      padding: 50px 200px;
      text-align: left;
      //width: calc(100% + 400px);
	  width: 100vw;
    }
    @include breakpoint($desktop) {
      left: -250px;
      padding: 50px 250px;
      //width: calc(100% + 500px);
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: -15px;
      left: 50%;
      width: 0;
      transform: translateX(-50%);
      z-index: z(1);
    }
      &.js-menu-active {
          left: 0;
          margin: 0;
          opacity: 1;
          padding: 0;
          position: relative;
          top: auto;
          visibility: visible;
          width: 100%;
      }
    li {
      display: block;
      //float: left;
      list-style: none;
      text-transform: none;
      a {
        color: palette(grey);
        display: block;
        font-size: 16px;
        line-height: 1;
        padding: 10px 15px;
        @include breakpoint($tablet) {
          font-weight: $font-weight-denim;
          padding: 10px 30px;
        }
      }
    }
  }
  @include breakpoint($tablet) {
    > ul li.menu-item-has-children:hover .sub-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

#mobile-toggle {
  display: inline-block;
  float: right;

  #open-mobile-menu {
    color: palette(white, pure);
    display: block;
    float: right;
    margin: 12px 5px 0 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    @include breakpoint($tablet) {
      display: none;
    }
  }
}
.nav-fixed #mobile-toggle #open-mobile-menu {
    color: palette(grey,dark);
}
