.flickity-viewport {
  transition: height $duration ease-in-out;
}

.flickity-button {
  background-color: palette(yellow);
  color: palette(white, pure);
  transition: background-color $duration ease-in-out;
  z-index: 3;
  &:hover {
    background-color: palette(yellow, dark);
  }
  &:disabled {
    opacity: 1;
  }

  .flickity-button-icon {
    fill: palette(white, pure);
    left: 30%;
    top: 30%;
    height: 40%;
    width: 40%;
  }
}

.flickity-prev-next-button {
  border-radius: 0;
  bottom: 0;
  top: auto;
  transform: none;
  &.previous {
    left: auto;
    right: 44px;
  }
  &.next {
    right: 0;
  }
}

.slider-counter {
  color: palette(yellow);
  font-weight: $font-weight-denim;
  margin: 0;
  position: absolute;
  bottom: 16px;
  right: 115px;
  line-height: 1;
  z-index: 3;

  span {
    color: palette(grey, lighter);
  }
}
