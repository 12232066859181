/*-------------------------------------------------------------------------------------------*/
/* Locations */
/*-------------------------------------------------------------------------------------------*/


.locations {
    margin-bottom: 90px;
}

.location {
    margin-bottom: 35px;

    .city-state {
        margin: 10px 0 0 0;
        color: palette(grey);
        font-family: $josefin;
        font-size: 14px;
        text-transform: uppercase;
    }
    .post-title {
        margin: 0;
        font-size: 18px;
        line-height: 26px;

        @include breakpoint($phablet) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}