.filter-bar {
    margin: 30px 0 15px 0;

    .filter-fields {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .facetwp-facet {
            margin: 0;
        }

        .filter-select {
            //flex: 1;
            margin: 0 25px 15px 0;

            .filter-label {
                color: palette(yellow);
                font-family: $josefin;
                font-size: 16px;
                font-weight: 700;
                line-height: 1rem;
                text-transform: uppercase;
            }

            select {
                padding: 6px 10px;
                font-family: $josefin;
                border-radius: 0;
                height: 32px;
            }
        }
        .filter-apply {
            //flex: 1;
            margin-bottom: 15px;

            .button {
                margin-bottom: 2px;
            }
        }
        .facetwp-dropdown {
            max-width: 100px;

            @include breakpoint($phablet) {
                max-width: inherit;
            }
        }
        .facetwp-checkbox,
        .facetwp-radio {
            background: none;
        }
        .filter-checkbox {
            flex: 1;
            text-align: left;
            margin-bottom: 15px;

            .facetwp-checkbox,
            .facetwp-radio {
                display: inline-block;
                padding: 6px 8px 2px 8px;
				font-size: 14px;
                font-family: $josefin;
                font-weight: 500;
                line-height: 2rem;
                border-bottom: 3px solid transparent;
				
				@include breakpoint($desktop) {
					font-size: 16px;
				}

                &.checked {
                    color: palette(yellow,dark);
                    background-color: palette(grey,lightest);
                    border-color: palette(yellow);
                }

                &:hover {
                    border-color: palette(yellow,dark);
                }
            }

            @include breakpoint($phone) {
                text-align: right;

                .facetwp-checkbox,
                .facetwp-radio {
                    margin: 0 0 0 14px !important;
					
					@include breakpoint($desktop) {
						margin: 0 0 0 25px !important;
					}
                }
            }
        }
    }

    .facetwp-selections {

        ul li {
            padding: 6px 0 6px 12px;
            //color: palette(white);
            background-color: palette(black);
        }

        .facetwp-selection-value,
        .facetwp-selection-label {
            filter: invert(1);
        }
    }
	
	.filter-search {
        display: inline-block;
        margin-bottom: 15px;

		.facetwp-facet {
			display: inline-block;
			margin: 0;
		}
		
		.facetwp-search {
			margin: 0 4px 0 0;
			padding: 0 40px 0 12px;
			font-size: 15px;
			color: palette(grey, dark);
			border: 1px solid palette(grey, lightest);
			vertical-align: top;
			
			&::placeholder {
				color: palette(grey);
			}
			
			&:focus {
				border: 1px solid palette(blue);
			}
		}
		
		.facetwp-btn::before {
			margin-top: 4px;
			margin-right: 6px;
			width: 14px;
			height: 14px;
			background-size: cover;
            display: none;
		}

        .btn-go {
            margin: 0;
            padding: 7px 12px;
            background-color: palette(blue, dark);
            vertical-align: middle;

            .svg-icon {
                height: 20px;
                width: 20px;
                margin: 0;

                svg {
                    height: 20px;
                    width: 20px;
                    margin: 0;
                }
            }
        }
	}
	
	.filter-categories {
		display: inline-block;
        margin-right: 25px;
        margin-bottom: 15px;
		
		.label {
			font-size: 13px;
			color: palette(grey, dark);
			letter-spacing: 2px;
			text-transform: uppercase;
		}
		.select-wrap {
			position: relative;
			display: inline-block;
			//margin-left: 8px;
			vertical-align: top;
			
			select {
				min-width: 230px;
                border: none;
                border-radius: 2px;
				padding: 1px 32px 1px 12px;
				color: palette(white);
                text-transform: uppercase;
                letter-spacing: 1px;
                background-color: palette(yellow);
			}
			
			&::after {
			    content: "\2303";
				position: absolute;
			    right: 10px;
			    top: -3px;
				color: palette(white);
				font-size: 20px;
                transform: rotate(180deg);
                pointer-events: none;
			}
		}
	}
}

.facetwp-counter {
    display: none !important;
}