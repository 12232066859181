/*-------------------------------------------------------------------------------------------*/
/* PANELS: BRANDS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-brands {
  margin-bottom: $panel-mobile-padding;
  margin-top: $panel-mobile-padding;
  @include breakpoint($tablet) {
    margin-bottom: $panel-desktop-padding;
    margin-top: $panel-desktop-padding;
  }

  .brands-information {
    text-align: center;
    @include breakpoint($tablet) {
      padding: 115px 50px;
      text-align: left;
    }
  }

  .subhead {
    color: palette(white, light);
    font-size: 96px;
    font-weight: $font-weight-normal;
    line-height: 1;
    margin-bottom: -15px;
    text-transform: lowercase;
      filter: contrast(0.9);
    @include breakpoint($tablet) {
      font-size: 120px;
      line-height: 124px;
      margin: 0;
      position: absolute;
      top: 5px;
      left: 0;
      width: 120%;
      z-index: -1;
    }
  }

  h3 {
      position: relative;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  .button-line {
    margin-right: 0;
  }

  .order-lg-2 {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .brands-container {
    margin: 0 auto;
    max-width: 400px;
    width: 50%;
    @include breakpoint($phablet) {
      width: 25%;
    }
    @include breakpoint($tablet) {
      max-width: none;
      //justify-content: flex-start;
    }

    .brand-image {
      position: relative;
      margin: 0 auto 65px;
      min-width: 105px;
      min-height: 48px;
      width: 50%;
      @include breakpoint($tablet) {
        margin: 0 auto;
        width: 25%;
      }
    }

    img {
      display: none;
      position: absolute;
      margin: 0 auto;
      //margin: 0 auto 65px;
      max-height: 48px;
      max-width: 105px;
      left: 0;
      right: 0;
        filter: grayscale(100%);
    }
  }
}