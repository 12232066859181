/*-------------------------------------------------------------------------------------------*/
/* ARCHIVE */
/*-------------------------------------------------------------------------------------------*/

body.blog,
body.category {
  .main {
    padding: $panel-mobile-padding 0;
    @include breakpoint($tablet) {
      padding: $panel-desktop-padding 0;
    }
  }
}
