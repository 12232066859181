// Bootstrap aligned, desktop-first approach
// NOTE: Remember to update javascript breakpoint definitions at top of ww-framework.js as these are changed
$phone: 480px;
$phablet: 768px;
$tablet: 992px;
$desktop: 1200px;

/**
  Mobile-first - use Breakpoint Sass library, already included via style.scss (http://breakpoint-sass.com/)
  Example usage:
  .test-component-responsive-font-size {
    font-size: 1rem;
    @include breakpoint($phablet) {
      font-size: 1.2rem;
    }
    @include breakpoint($tablet) {
      font-size: 1.6rem;
    }
    @include breakpoint($desktop) {
      font-size: 22rem;
    }
  }
*/

// Desktop-first - use these mixins
@mixin large {
    @media (max-width: #{$desktop - 1}) {
        @content;
    }
}

@mixin medium {
    @media (max-width: #{$tablet - 1}) {
        @content;
    }
}

@mixin smedium {
    @media (max-width: #{$phablet - 1}) {
        @content;
    }
}

@mixin small {
    @media (max-width: #{$phone}) {
        @content;
    }
}

/*
 Z-indexing values
 Think of these z-index maps indices as "tiers".
 When an element needs a higher z-index, bump up the value in increments of 1 until things work (z(1), z(2), etc.),
 keeping the minimum  value necessary to get the job done. This level of abstraction can help, in that you are writing
 your custom z-index  values with relative tiers that will always be correct, BUT if a new  toolkit is introduced with
 some wacky z-indices  that you need to account for, you can just change the actual z-index values in the map to make
 things work, making sure  that they remain in ascending order relative to the keys.
*/
$Z: (
    -1: -1,
    0: 0,
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 1000,
);

// Palette color map retrieval function
@function z($i) { // Default shade: base
    // Return warning if color is invalid
    @if not map-has-key($Z, $i) {
        @warn "There is no x-index mapped index '#{$i}'";
        @return null;
    }

    // Return the proper z-index
    @return map-get($Z, $i);
}
