/*-------------------------------------------------------------------------------------------*/
/* MASTHEAD */
/*-------------------------------------------------------------------------------------------*/

.masthead {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: palette(white, pure);
  min-height: 520px;
  padding-top: 80px;
  position: relative;
  @include breakpoint($tablet) {
    min-height: 600px;
  }
    //&::after {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100%;
    //    background-color: rgba(palette(blue,dark), 0.4);
    //}
  &.masthead-cta,
  &.masthead-landing {
    min-height: 640px;
    @include breakpoint($tablet) {
      min-height: 800px;
    }
  }
  &.masthead-landing,
  &.masthead-landing-video {
    .masthead-title {
      &:after {
        background: url("../images/down-arrows.png") no-repeat 0 0;
        background-size: 16px;
        content: "";
        display: block;
        height: 27px;
        margin: 30px auto 0;
        width: 16px;
        @include breakpoint($tablet) {
          margin-top: 80px;
        }
      }
    }
  }
  &.masthead-landing-video {
    @include breakpoint($tablet) {
      padding-top: 0;
    }
  }

  .masthead-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
      z-index: z(1);
  }

  .masthead-subtitle {
    font-size: 12px;
    font-weight: $font-weight-heavy;
    letter-spacing: 1.5;
    line-height: 16px;
    text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(palette(black), 0.3);
  }

  .masthead-title {
    margin: 15px 0 10px;
      text-shadow: 0px 0px 5px rgba(palette(black), 0.3);
  }

  .masthead-description {
    font-family: $freight;
    font-size: 18px;
    line-height: 36px;
    @include breakpoint($tablet) {
      font-size: 20px;
      line-height: 38px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .masthead-button {
    .button {
      margin-bottom: 0;
      bottom: 0;
      left: 0;
      position: absolute;
        z-index: z(1);
    }
  }
  
  .masthead-caption {
	  position: absolute;
	  bottom: 10px;
	  right: 20px;
	  color: palette(white);
	  font-family: $josefin;
	  font-style: italic;
	  text-shadow: 0 0 2px rgba(palette(black), 0.5);
	  
	  a {
		  color: palette(white);
	  }
  }

  .awards {
    bottom: 0;
    position: absolute;
    width: 100%;
      z-index: z(1);
  }

  .awards-container {
    bottom: -24px;
    position: absolute;
    right: 0;
	text-align: center;

    img {
      max-height: 70px;
      max-width: 100px;
      @include breakpoint($tablet) {
        max-height: 120px;
        max-width: 150px;
      }
    }

      .learn-more {
          display: block;
          width: 100%;
          height: 24px;
          padding: 5px 8px;
          font-family: $josefin;
          font-size: 10px;
          line-height: 1.5rem;
          text-transform: uppercase;
          text-align: center;
          background-color: rgba(0,0,0,0.65);

          &:hover {
              background-color: rgba(0,0,0,1);
          }
      }
  }
}

.logo-landing {
  fill: palette(white, pure);
  max-width: 200px;
    filter: drop-shadow( 2px 2px 6px rgba(palette(black),0.4) );
  @include breakpoint($tablet) {
    max-width: 270px;
  }
}

// Masthead Video
.masthead-video-container {
  display: table;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
    min-height: 600px;

  .masthead-video {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    @include breakpoint($tablet) {
      display: block;
    }
  }

  video {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}
