/*-------------------------------------------------------------------------------------------*/
/* PANELS: TEXT */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-text-image {
  margin: 64px 0;
  @include breakpoint($tablet) {
    margin: 120px 0;
  }

  // LARGE
  &.size-large {
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    &.align-left {
      @include breakpoint($tablet) {
        background-position: center left;
      }
      .text-image-information {
        @include breakpoint($tablet) {
          background: linear-gradient(-90deg, #f8f9f9 50%, rgba(248, 249, 249, 0.88) 100%);
        }
      }
    }
    &.align-right {
      @include breakpoint($tablet) {
        background-position: center right;
      }
      .text-image-information {
        @include breakpoint($tablet) {
          background: linear-gradient(90deg, #f8f9f9 50%, rgba(248, 249, 249, 0.88) 100%);
        }
      }
    }

    .container-fluid {
      padding: 0;
    }

    .text-image-information {
      background: rgb(248,249,249);
      background: linear-gradient(0deg, #f8f9f9 90%, rgba(248, 249, 249, 0.88) 100%);
      margin-top: 50%;
      padding: 50px 30px 112px;
      @include breakpoint($tablet) {
        margin-top: 0;
        padding: 196px 136px 228px;
      }
    }
  }

  // SMALL
  &.size-small {
    &.align-right {
      .concord-mark {
        left: 0;
      }
    }
    &.align-left {
      .concord-mark {
        right: 0;
        transform: rotate(180deg);
      }
    }

    .concord-mark {
      display: none;
      max-width: 170px;
      @include breakpoint($tablet) {
        display: block;
        position: absolute;
      }
    }
    #concord-mark-fill1,
    #concord-mark-fill2 {
      fill: palette(white);
    }

    .row {
      padding: 0 15px;
      @include breakpoint($tablet) {
        padding: 0;
      }
    }

    .text-image-information {
      padding: 40px 0 0;
    }

    .button {
      background-color: transparent;
      bottom: auto;
      color: palette(yellow);
      margin-bottom: 40px;
      min-width: 0;
      padding: 0;
      position: relative;
      right: auto;
      @include breakpoint($tablet) {
        margin-bottom: 0;
      }
      &:before {
        content: none;
      }
      &:after {
        background-color: palette(yellow);
        content: "";
        display: block;
        font-family: $futura;
        height: 1px;
        margin: 0 auto;
        position: relative;
        right: auto;
        top: auto;
        transition: width $duration ease-in-out;
        transform: none;
        width: 100%;
      }
      &:hover {
        &:after {
          width: calc(100% - 24px);
        }
      }

      span {
        padding-bottom: 5px;
        display: block;
      }
    }
  }

  .subhead {
    color: palette(grey, light);
    letter-spacing: 1.6px;
  }

  h2 {
    margin-top: 10px;
  }

  .button {
    bottom: 0;
    margin: 0;
    position: absolute;
    right: 0;
  }
}