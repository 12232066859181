/*-------------------------------------------------------------------------------------------*/
/* FOOTER FORM */
/*-------------------------------------------------------------------------------------------*/

.footer-form {
  padding: 64px 0;
  position: relative;
  @include breakpoint($tablet) {
    padding: 140px 0;
  }
  &.form-dark {
    background-color: palette(black, light);
    color: palette(grey, light);

    h3,
    h4 {
      color: palette(white);
    }

    .concord-mark {
      opacity: 1;
    }

    form input,
    .ginput_container input,
    form textarea,
    .ginput_container > textarea {
      color: palette(white);
    }
  }

  // If contact information, add margin
  .row + .row {
    margin-top: 48px;
  }

  .concord-mark {
    bottom: 0;
    max-width: 150px;
    opacity: .025;
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    @include breakpoint($tablet) {
      max-width: 200px;
    }
  }

  #concord-mark-fill1,
  #concord-mark-fill2 {
    fill: palette(black);
  }

  h3 {
    font-size: 30px;
    line-height: 38px;
    margin-top: 0;
    @include breakpoint($tablet) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  h4 {
    font-family: $futura;
    font-size: 22px;
    font-weight: $font-weight-heavy;
    line-height: 30px;
    margin: 0 0 10px;
    @include breakpoint($tablet) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  i {
    color: palette(yellow);
  }

  .footer-form-content {
    p {
      margin-left: 18px;
    }
  }

  form input,
  .ginput_container input,
  form textarea,
  .ginput_container > textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid palette(grey);
    padding: 10px 0 !important;
    &:active,
    &:focus {
      border-color: palette(yellow);
    }
  }

  .gform_wrapper .top_label .gfield_label,
  .gform_wrapper .ginput_complex label {
    display: none;
  }

  .button {
    display: block;
    margin: 48px auto 0;
    @include breakpoint($tablet) {
      margin: 48px 0;
    }
  }
}
