.panel-timeline {
  background-color: palette(black, light);
  color: palette(white, pure);
  padding-bottom: 32px;
  @include breakpoint($tablet) {
    padding-bottom: $panel-desktop-padding;
    padding-top: 200px;
  }

  .timeline-container {
    margin: 0 auto;
    max-width: 1170px;
    overflow: hidden;
  }

  .timeline-slide-content {
    margin: 0 auto;
    max-width: 530px;
    padding: 40px 30px 0;
    @include breakpoint($tablet) {
      max-width: none;
      padding: 60px 30px 60px 136px;
      width: calc(100% - 468px);
    }
  }

  .subhead {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  h3 {
    margin: 15px 0;
  }

  .description {
    color: palette(grey, light);
  }
}

.timeline-slider {
  position: relative;
  display: flex;
  transition: transform 1200ms cubic-bezier(1, 0, .22, 1);
  z-index: 5;
}

.timeline-slide {
  position: relative;
  flex: 1 0 100%;
  flex-flow: column;
  display: flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  @include breakpoint($tablet) {
    flex-flow: row;
    margin-bottom: 70px;
  }
}

.timeline-navigation-container {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  width: 100vw;
  padding-left: 75px;
  transition: transform 1200ms cubic-bezier(1,0,0.22,1);
  @include breakpoint($tablet) {
    padding-left: 295px;
  }
}

.timeline-navigation-inner-container {
  position: relative;
  display: flex;
}

.timeline-navigation-item {
  color: palette(yellow);
  position: relative;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: $font-weight-heavy;
  cursor: pointer;
  &.timeline-active {
    opacity: 1;
    &:after {
      border-color: palette(yellow);
    }
    &:before {
      background-color: palette(white, pure);
    }
    .timeline-navigation-text {
      opacity: 1;
      @include breakpoint($tablet) {
        font-size: 24px;
      }
    }
  }
  &:before {
    background-color: palette(grey);
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 30px;
    width: 100%;
    margin-top: 4px;
    height: 3px;
    transition: $duration linear;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    right: 100%;
    top: 30px;
    width: 12px;
    height: 12px;
    margin-right: -0.75rem;
    box-sizing: border-box;
    border: 6px solid palette(grey);
    border-radius: 50%;
    transition: border 225ms linear;
  }
}

.timeline-navigation-text {
  opacity: .25;
  transition: $duration linear;
  @include breakpoint($tablet) {
    font-size: 14px;
  }
}

.timeline-next-slide {
  color: palette(white, pure);
  cursor: pointer;
  display: block;
  margin: 0 0 50px;
  max-width: 530px;
  padding: 0 30px;
  text-align: right;
  width: 100%;
  @include breakpoint($tablet) {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  i {
    padding: 18px;
  }
}

.timeline-next-slide {

}

.timeline-start-over {
  cursor: pointer;
  display: block;
  font-weight: $font-weight-heavy;
  text-transform: uppercase;
  position: absolute;
  top: 18px;
  min-width: 100px;
  text-align: center;
}
