/*-------------------------------------------------------------------------------------------*/
/* FRONT PAGE */
/*-------------------------------------------------------------------------------------------*/

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: palette(blue,dark);
    z-index: z(4);
}

#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -100px 0 0 -100px;
    /* is width and height divided by two */
}