/*-------------------------------------------------------------------------------------------*/
/* PANELS: QUOTES */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-quotes {
  background-color: palette(white);
  color: palette(grey);
  //padding-bottom: $panel-mobile-padding;
  padding-top: $panel-mobile-padding;
  position: relative;
  text-align: center;
  @include breakpoint($tablet) {
    //padding-bottom: $panel-desktop-padding;
    padding-top: $panel-desktop-padding;
  }

  .quotes-carousel {
    padding-bottom: $panel-mobile-padding;
    @include breakpoint($tablet) {
      padding-bottom: $panel-desktop-padding;
    }
  }

  .carousel-cell {
    padding: 0 30px;
    width: 100%;
    @include breakpoint($tablet) {
      padding: 0 60px;
      width: 50%;
    }
    @include breakpoint($desktop) {
      padding: 0 136px;
    }
  }

  i.icon-quote {
    color: palette(grey, lighter);
    display: block;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .quote {
    font-size: 16px;
    line-height: 40px;
    @include breakpoint($tablet) {
      font-size: 20px;
    }
  }

  .quottee {
    @include breakpoint($tablet) {
      align-items: center;
      display: flex;
      text-align: left;
    }
  }

  .quottee-image {
    background-color: palette(white, light);
    border-radius: 50%;
    display: block;
    height: 64px;
    margin: 0 auto 10px;
    padding: 6px;
    width: 64px;
    @include breakpoint($tablet) {
      margin: 0 15px 0 0;
    }
  }

  .quottee-info {
    color: palette(grey, light);
    font-size: 12px;
    font-weight: $font-weight-heavy;
    line-height: 16px;

    p {
      margin: 0;
    }

    a {
      display: inline-block;
      margin-top: 5px;
    }

    i.icon-play-small {
      display: inline-block;
      vertical-align: middle;
    }
  }
    
    &.quotecount-1 {
        .slider-counter,
        .flickity-button {
            display: none;
        }
    }
    @include breakpoint($tablet) {
        &.quotecount-2 {
            .slider-counter,
            .flickity-button {
                display: none;
            }
        }
    }
}