.mpfy-container {
    color: palette(grey) !important;
}

.mpfy-controls .mpfy-filter {
    float: left !important;
}
.mpfy-mll-location .mpfy-mll-l-pin {
    display: none !important;
}
.mpfy-mll-location .mpfy-mll-l-title {
    padding: 0 !important;
}

.mpfy-mll-location .mpfy-mll-l-heading {
    border-color: palette(grey,lighter) !important;
}
.mpfy-mll-location.active .mpfy-mll-l-heading {
    background-color: palette(white) !important;
}
.mpfy-mll-location .mpfy-mll-l-content {
    background-color: palette(white,light) !important;
}


.mpfy-mll-location .mpfy-mll-l-buttons a {
    border-radius: 0 !important;
    color: palette(white) !important;
    background-color: palette(yellow) !important;

    &:hover {
        color: palette(white) !important;
        background-color: palette(yellow,dark) !important;
    }
}
.mpfy-mll-location .mpfy-mll-l-buttons a + a,
body .mpfy-p-widget-direction a {
    background-color: palette(blue) !important;

    &:hover {
        background-color: palette(blue,dark) !important;
    }
}

.mpfy-controls.mpfy-without-search .mpfy-filter, .mpfy-controls.mpfy-without-search .mpfy-filter .selecter,
.mpfy-controls .mpfy-filter .selecter.open .selecter-options, .mpfy-selecter-wrap .selecter.open .selecter-options,
.mpfy-mll-location .mpfy-mll-l-categories a {
    border-radius: 0 !important;
}
.mpfy-controls .mpfy-filter .selecter .selecter-options {
    left: 0 !important;
    right: auto !important;
}
.mpfy-mll-location .mpfy-mll-l-categories a:hover {
    background-color: palette(blue) !important;
}

.mpfy-p-widget-location h5.mpfy-p-widget-title:after {
    content: none !important;
}
.mpfy-p-widget-location .mpfy-location-details {
    position: inherit !important;
    padding-bottom: 10px !important;
    visibility: visible !important;
    opacity: 1 !important;
    top: 44px !important;
}

.mpfy-tags-list,
.mpfy-mll {
    max-width: 900px !important;
    margin: 0 auto !important;
}

.portfolio-map {
    margin-top: 36px;
}