// Font stacks
$stack-serif: Georgia, Times, 'Times New Roman', _serif;
$stack-sans-serif: Helvetica, Arial, _sans-serif;

$josefin: 'Josefin Sans', $stack-sans-serif;
$futura: 'futura-pt', $stack-sans-serif;
$freight: 'freight-text-pro', $stack-sans-serif;
$icomoon: 'icomoon';

// Font weights
$font-weight-normal: 400;
$font-weight-heavy: 700;
$font-weight-denim: 600;
