/*-------------------------------------------------------------------------------------------*/
/* FORMS */
/*-------------------------------------------------------------------------------------------*/

// A bunch of Gravity Form overrides

ul.gform_fields {
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li {
        margin: 0 0 10px;
        padding: 0;
        &.gf_left_half,
        &.gf_right_half {
            @include breakpoint($tablet) {
                float: left;
                width: 50%;
            }
        }
        &.gf_left_half {
            @include breakpoint($tablet) {
                padding-right: 10px;
            }
        }
    }

    .ginput_complex.has_first_name.has_last_name {
        @include breakpoint($tablet) {
            display: flex;
        }
        .name_first,
        .name_last {
            display: block;
            margin: 0 0 10px;
            @include breakpoint($tablet) {
                width: 50%;
            }
        }
        .name_first {
            @include breakpoint($tablet) {
                padding-right: 10px;
            }
        }
    }
}

form input,
.ginput_container input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 10px !important;
    color: $color-form-text;
    font-size: 1em !important;
    line-height: 1.2;
    border: 1px $color-form-border solid;
    background-color: palette(white);
    background-image: none;
    transition: border-color ease-in-out $duration, box-shadow ease-in-out $duration;
}

form textarea,
.ginput_container > textarea {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: $color-text;
    border: 1px palette(grey, light) solid;
    background-color: palette(white);
    background-image: none;
    transition: border-color ease-in-out $duration, box-shadow ease-in-out $duration;
    height: auto;
    overflow: auto;
}

form input[type="text"],
.ginput_container > input[type="text"] {
    height: 40px;
}

form input[type="text"]::-webkit-input-placeholder,
form input[type="text"]:-moz-placeholder, /* Firefox 18- */
form input[type="text"]::-moz-placeholder, /* Firefox 19+ */
form input[type="text"]:-ms-input-placeholder,
form input[type="text"]::placeholder-shown,
.ginput_container > input[type="text"]::-webkit-input-placeholder,
.ginput_container > input[type="text"]:-moz-placeholder, /* Firefox 18- */
.ginput_container > input[type="text"]::-moz-placeholder, /* Firefox 19+ */
.ginput_container > input[type="text"]:-ms-input-placeholder,
.ginput_container > input[type="text"]::placeholder-shown {
    color: $color-form-placeholder !important;
    font-size: 1.44rem !important;
    font-style: italic !important;
}

form input:focus,
.ginput_container > input:focus,
.ginput_container > textarea:focus,
.gr_bs_form > .ginput_container > textarea:focus {
    outline: 0;
}

form input[type="submit"],
.gform_button,
form button{
    //display: inline-block;
    //height: 40px;
    //padding: 10px 20px;
    text-align: left;
    //vertical-align: middle;
    border: 0;
    //background-image: none;
    //background-color: palette(yellow);
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
}

.gfield_required {
    color: $color-form-required !important;
}

.gfield_description {
    font-style: italic;
}

.gform_wrapper .hidden_label input.large,
.gform_wrapper .hidden_label select.large,
.gform_wrapper textarea.large,
.gform_wrapper .top_label input.large,
.gform_wrapper .top_label select.large,
.gform_wrapper .top_label .gfield_error {
    width: 100% !important;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: transparent !important;
    border: 0 !important;
    margin: 0 !important;
}

.gform_wrapper .gform_ajax_spinner {
    clear: both;
    max-width: 50px;
    text-align: center;
    padding-top: 2px;
    margin: 0 0 0 auto;
}

.gform_wrapper .top_label .gfield_label {
    margin-bottom: 0 !important;
}

.gfield_checkbox li,
.gfield_radio li {
    margin-bottom: 0 !important;
}

.gfield_checkbox input,
.gfield_radio input {
    margin-top: 0 !important;
    margin-left: 1px !important;
}

.gfield_checkbox label,
.gfield_radio label {
    margin-top: 8px !important;
}

.gform_wrapper .gfield_error .gfield_label {
    color: $color-form-error !important;
}

.gform_wrapper .ginput_complex label {
    margin-top: 0;
}

