/*-------------------------------------------------------------------------------------------*/
/* PANELS: CTAS */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-ctas {
  margin-bottom: $panel-mobile-padding;
  margin-top: $panel-mobile-padding;
  @include breakpoint($tablet) {
    margin-bottom: $panel-desktop-padding;
    margin-top: $panel-desktop-padding;
  }

  .cta-container {
    background-position: center center;
    background-size: cover;
    color: palette(white, pure);
    margin: 0 auto 30px;
    max-width: 325px;
    min-height: 200px;
    position: relative;
    @include breakpoint($tablet) {
      min-height: 284px;
      max-width: none;
    }

      //&::after {
      //    content: '';
      //    position: absolute;
      //    top: 0;
      //    left: 0;
      //    width: 100%;
      //    height: 100%;
      //    background-color: rgba(palette(blue,dark), 0.4);
      //}

      > * {
          z-index: z(1);
      }
  }

  h3 {
    left: 32px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
      text-shadow: 0px 0px 5px rgba(palette(black), 0.3);
    @include breakpoint($tablet) {
      font-size: 34px;
    }
  }

  .button {
    bottom: -15px;
    left: 0;
    margin: 0;
    position: absolute;
  }
}