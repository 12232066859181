/*-------------------------------------------------------------------------------------------*/
/* BASE */
/*-------------------------------------------------------------------------------------------*/
.page-content {
    position: relative;
    width: 100%;
    height: auto;
    z-index: z(0);
    overflow: hidden;
    padding: 0;
    min-height: 500px;
}

.container {
    max-width: 1170px;
    width: 100%;
}

.hide-for-mobile {
    display: inline-block;
}

.show-for-mobile {
    display: none;
    padding: 30px;
}

@media (max-width: 767px) {
    .hide-for-mobile {
        display: none;
    }

    .show-for-mobile {
        display: inline-block;
    }
}
