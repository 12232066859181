/*-------------------------------------------------------------------------------------------*/
/* SINGLE TEAM */
/*-------------------------------------------------------------------------------------------*/

body.single-team {
  article {
    padding-bottom: $panel-mobile-padding;
    padding-top: 64px + $panel-mobile-padding;
    @include breakpoint($tablet) {
      padding-bottom: $panel-desktop-padding;
      padding-top: 64 + $panel-desktop-padding;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  h4.social-media-connect {
    color: palette(grey, light);
    font-family: $futura;
    font-size: 12px;
    font-weight: $font-weight-denim;
    letter-spacing: 1.8px;
    line-height: 16px;
    margin: 0 0 10px;
    @include breakpoint($tablet) {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 15px;
    }
  }

  .social-container {
    margin-top: 40px;
    @include breakpoint($tablet) {
      margin-top: 0;
    }
  }

  ul.social-media {
    > li {
      > a {
        border-color: palette(grey, lighter);
        &:hover {
          border-color: palette(yellow);
        }
      }
    }
  }

  .subhead {
    color: palette(grey, light);
    margin: 0;
  }

  h1 {
    font-size: 32px;
    line-height: 36px;
    margin: 15px 0;
    @include breakpoint($tablet) {
      font-size: 40px;
      line-height: 44px;
    }
  }
}

img.team-photo {
  border-bottom: 4px solid palette(yellow);
  margin-bottom: 30px;
  @include breakpoint($tablet) {
    margin-bottom: 40px;
  }
}