/*-------------------------------------------------------------------------------------------*/
/* PANELS: TEAM */
/*-------------------------------------------------------------------------------------------*/

.panel.panel-team {
  margin-bottom: $panel-mobile-padding;
  margin-top: $panel-mobile-padding;
  @include breakpoint($tablet) {
    margin-bottom: $panel-desktop-padding;
    margin-top: $panel-desktop-padding;
  }

  .col-md-6 {
    &:last-of-type {
      a {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: palette(grey, light);
    display: block;
    margin: 0 auto 30px;
    max-width: 300px;
    &:hover {
      h2 {
        color: palette(yellow);
      }
    }
  }

  .team-photo-default {
    img.team-photo {
      margin-bottom: 0;
    }
  }

  img.team-photo {
    margin-bottom: 10px;
  }

  h2 {
    color: palette(grey);
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    transition: color $duration ease-in-out;
  }

  .subhead {
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 20px;
  }
}
