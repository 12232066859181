/*-------------------------------------------------------------------------------------------*/
/* BUTTONS */
/*-------------------------------------------------------------------------------------------*/

.button {
  background-color: palette(yellow);
  color: palette(white);
  display: inline-block;
    font-family: $josefin;
  font-size: 14px;
  font-weight: $font-weight-denim;
  letter-spacing: 1px;
  line-height: 1;
  margin: 11px 15px 11px 0;
  min-width: 215px;
  padding: 28px 32px 24px 32px;
  position: relative;
  text-transform: uppercase;

  &:hover {
    &:before {
      transform: scaleX(1);
    }
    &:after {
      right: 28px;
    }
  }

  &:visited,
  &:link {
    color: palette(white);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: palette(yellow, dark);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    transition-property: transform, -webkit-transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
  }

  &:after {
    content: '\e904';
    display: block;
    font-family: $icomoon;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    transition: right $duration ease-in-out;
  }

  span {
    position: relative;
    z-index: 2;
  }

    &.button-sm {
        min-width: auto;
        padding: 14px 16px 12px 16px;
        cursor: pointer;

        &:hover {
            background-color: palette(yellow, dark);
        }

        &:before,
        &:after {
            content: none;
        }
    }

    &.button-xsm {
        display: inline-block;
        min-width: auto;
        padding: 8px 10px 6px 8px;
        padding-right: 40px;
        font-size: 12px;

        &:after {
            content: '\e904';
            display: block;
            font-family: $icomoon;
            position: absolute;
            right: 12px;
        }
    }
}

.button-line {
  font-size: 12px;
  font-weight: $font-weight-denim;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 2;
  margin: 15px 15px 15px 0;
  text-transform: uppercase;

  &:hover {
    &:after {
      width: calc(100% - 24px);
    }
  }

  &:after {
    background-color: palette(yellow);
    content: '';
    display: block;
    height: 1px;
    margin: 0 auto;
    transition: width $duration ease-in-out;
    width: 100%;
  }
}
